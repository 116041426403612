import { graphql, useStaticQuery } from "gatsby"

export const useGalleryImages = () => {
  const {
    strapiGallery: { images },
  } = useStaticQuery(graphql`
    query useGalleryImages {
      strapiGallery {
        images {
          Image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 75, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  return images.map((image) => image.Image)
}
