import React from "react"
import Gallery from "../components/Gallery/Gallery"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"

const GallerySection = styled(Section)``

// https://www.npmjs.com/package/react-image-video-lightbox
// www.npmjs.com/package/react-grid-gallery

const _Intro = styled.div`
  margin-bottom: 50px;
`
const GalleryPage = () => (
  <Layout fluid={true}>
    <SEO title="Galéria" />
    <GallerySection>
      <Container fluid={false}>
        <_Intro
          data-sal-duration="300"
          data-sal="fade"
          data-sal-easing="ease-in"
        >
          <span className="upTitle">Autoškola Hron</span>
          <h1>Galéria</h1>
        </_Intro>
        <Gallery />
      </Container>
    </GallerySection>
  </Layout>
)

export default GalleryPage
