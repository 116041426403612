import { Grid, useMediaQuery } from "@material-ui/core"
import { FluidObject } from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import styled from "styled-components"
import { muiTheme } from "../../../lib/theme"

export interface GalleryPhoto {
  publicURL: string
  childImageSharp: {
    fluid: FluidObject
  }
}

export interface UniversalGalleryProps {
  className?: string
  images: GalleryPhoto[]
  galleryKey?: string
}

const ImageCard = styled.a`
  display: flex;
  height: 300px;
  max-height: 300px;
  overflow: hidden;
  position: relative;

  margin: auto;

  ${muiTheme.breakpoints.down("sm")} {
    height: 100px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`

const UniversalGalleryInner = (props: UniversalGalleryProps) => {
  const { images, galleryKey } = props
  const matches = useMediaQuery(muiTheme.breakpoints.down("sm"))

  return (
    <div className={props.className}>
      <SimpleReactLightbox>
        <SRLWrapper key={galleryKey}>
          <Grid container spacing={matches ? 3 : 6}>
            {images.map((image, index) => (
              <Grid
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={index * 50}
                data-sal-easing="ease"
                item
                xs={6}
                sm={4}
                md={4}
                lg={4}
              >
                <ImageCard key={image.localFile.publicURL} href={image.localFile.publicURL}>
                  <GatsbyImage className="h-full w-full" image={getImage(image.localFile)} />
                </ImageCard>
              </Grid>
            ))}
          </Grid>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  )
}

export const UniversalGallery = styled(UniversalGalleryInner)``
