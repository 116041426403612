import { UniversalGallery } from "../../components/shared/UniversalGallery/UniversalGallery"
import React from "react"
import { useGalleryImages } from "./hooks/useGalleryImages"

interface Props {}
const Gallery: React.FC<Props> = () => {
  const images = useGalleryImages()
  return <UniversalGallery images={images} />
}

export default Gallery
